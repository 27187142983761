import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import { documentToReactComponents} from '@contentful/rich-text-react-renderer'
import experienceStyles from './experience.module.scss'
import Head from '../components/head'

export const query = graphql`
query{
    Profession:allContentfulExperience(filter: {slug:{eq:"professionalExperience"}},
                    sort:{fields: from, order:DESC}){
                        edges{
                            node{
                                company
                                from(formatString:"MMM-YYYY")
                                to(formatString:"MMM-YYYY")
                                location
                                role
                                duties{
                                    json
                                }
                            }
                        }
                    }
    Education: allContentfulEducation(sort:{fields:from,order:DESC}){
                    edges{
                    node{
                        university
                        location
                        from(formatString:"MMM-YYYY")
                        to(formatString:"MMM-YYYY")
                        degree
                        gpa
                    }
                    }
                }
    
}`


const ExperiencePage = (props) =>{
    
    return(
        <Layout>
            <Head title="Experience"/>
            <div className={experienceStyles.mainContent}>
                <h2 className={experienceStyles.headingFont}>Professional Experience</h2>
                {props.data.Profession.edges.map((edge)=>{
                    return(
                        <div>
                            <h3>{edge.node.company}</h3>
                            <p><i>{edge.node.location}</i>, {edge.node.from} - {edge.node.to ? edge.node.to : "Present"}</p>
                            <p>{edge.node.role}</p>
                            {documentToReactComponents(edge.node.duties.json)}
                        </div>
                    
                    )
                })}
                
                <h2 className={experienceStyles.headingFont}>Education</h2>
                {props.data.Education.edges.map((edge)=>{
                    return(
                        <div>
                            <h3>{edge.node.university}</h3>
                            <p><i>{edge.node.location}</i>, {edge.node.from} - {edge.node.to}</p>
                            <p>{edge.node.degree}</p>
                            <p>{edge.node.gpa}</p>
                        </div>
                   
                    )
                })}
            </div>
            
            
        </Layout>
    )
}

export default ExperiencePage